import objectFitImages from "object-fit-images";
import { NavController } from "./controllers/NavController";
import { ScrollController } from "./controllers/ScrollController";
import { YoutubeController } from "./controllers/YoutubeController";
import { DoctorController } from "./controllers/DoctorController";
import { SearchController } from "./controllers/SearchController";
import { BlogController } from "./controllers/BlogController";
import { Glossary } from "./components/glossary";
import { PopinController } from "./controllers/PopinController";
import { RdvCardsController } from "./controllers/RdvCardsController";
import { TestVisionController } from "./controllers/TestVisionController";
import { FirstApptController } from "./controllers/FirstApptController";
import { TrackerController } from "./controllers/TrackerController";

import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay]);

import { Router } from "./Router";

objectFitImages();

/*
 * Matches a body class with a controller. Classes can be passed via a string or an array.
 * The router stops at the first matched route.
 */
const router = new Router([
    {
        classes: 'single-medecin',
        controller: DoctorController,
    },
    {
        classes: 'blog',
        controller: BlogController,
    },
    {
        classes: 'home',
        controller: RdvCardsController,
    },
]);

document.addEventListener('DOMContentLoaded', () => {
  router.start();
  new NavController();
  new ScrollController();
  new YoutubeController();
  new SearchController();
  new Glossary();
  new PopinController();
  new TrackerController();
  if (document.querySelector('.tlv-block')) {
      new TestVisionController();
  }
  if (document.querySelector('.first-appointment-block')) {
      new FirstApptController();
  }
});
