export class DoctorController {
    constructor()
    {
        this.init_appointment();
    }

    init_appointment()
    {
        (function ($) {
            $(document).ready(function () {
                const appointment = document.querySelector(".appointment");

                const new_appointment = document.querySelector(".appointment .date");
                const cta_appointment = document.querySelector(".appointment .cta-appointment");

                const env = appointment.getAttribute('data-id-alax');
                const idlieu = appointment.getAttribute('data-id-lieux');
                const idc = appointment.getAttribute('data-idc');
                const idpraticien = appointment.getAttribute('data-id-doctor');

                cta_appointment.classList.add("disabled");

                $.ajax({
                    url: env+"/ajax/rdv_dispo_praticien.php?idc="+idc+"&idlieu="+idlieu+"&idpraticien="+idpraticien,
                    type: "POST",
                    dataType: "json",
                    crossDomain: true,
                    format: "json",
                    success:function (json) {
                        if (json.length !== 0){
                            jQuery.each(json, function (i, val) {
                                cta_appointment.classList.remove("disabled");
                                new_appointment.innerHTML = "<strong>"+ val['date'] +"</strong>";
                                return false;
                            });
                        }else{
                            cta_appointment.classList.add("disabled");
                            new_appointment.innerHTML = "<strong>Aucun rendez-vous disponible.</strong>";
                        }
                    },
                    error:function (json) {
                        cta_appointment.classList.add("disabled");
                        new_appointment.innerHTML = "<strong>Aucun rendez-vous disponible.</strong>";
                    },
                });
            });
        })(jQuery);
    }
}
