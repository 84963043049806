export class BlogController {
    constructor()
    {
        this.handleFilterFormAction();
    }

    handleFilterFormAction()
    {
        let filter_select = document.querySelector('.blog .blog-categ');
        let input_search = document.querySelector('.blog .blog-search');
        let form = document.querySelector('.blog .filter-form');

        let blog_url = document.querySelector('.blog .filter-posts').dataset.blogUrl;

        if (filter_select && input_search){
            filter_select.addEventListener('change', (event) => {
                let search =  input_search.value;
                let categ = event.target.value;
                let url = blog_url+categ+'?s='+search;
                form.action = url;
            });
            input_search.addEventListener('change', (event) => {
                let search = event.target.value;
                let categ = filter_select.value;
                let url = blog_url+categ+'?s='+search;
                form.action = url;
            });
        }
    }
}
