export class TrackerController {
    constructor()
    {
        this.handleCtaPhone();
        this.handleCtaMail();
        this.handleCtaItinerary();
        this.handleCtaSocial();
        this.handleCtas();
        this.handleFaq();
    }

    handleCtaPhone() {
        let btnPhones = document.querySelectorAll('.button-phone');

        if (btnPhones) {
            Array.from(btnPhones).forEach(btnPhone => {
                btnPhone.addEventListener('click', function (e) {
                    let btnContext;
                    if (btnPhone.parentElement.parentElement.parentElement.parentElement.classList.contains('prehead')) {
                        btnContext = 'Header';
                    }
                    else {
                        btnContext = 'Fiche centre';
                    }
                    if( typeof dataLayer !== "undefined") {
                        dataLayer.push({
                            'event': 'click_phone',
                            'context': btnContext,
                            'phone_contact': btnPhone.innerText,
                        })
                    }
                });
            });
        }

    }

    handleCtaMail() {
        let btnMails = document.querySelectorAll('.button-mail');

        if (btnMails) {
            Array.from(btnMails).forEach(btnMail => {
                btnMail.addEventListener('click', function (e) {
                    if( typeof dataLayer !== "undefined") {
                        dataLayer.push({
                            'event': 'click_mail',
                            'mail_contact': btnMail.innerText,
                        })
                    }
                });
            });
        }

    }

    handleCtaItinerary() {
        let btnItineraries = document.querySelectorAll('.button-itinerary');

        if (btnItineraries) {
            Array.from(btnItineraries).forEach(btnItinerary => {
                btnItinerary.addEventListener('click', function (e) {
                    if( typeof dataLayer !== "undefined") {
                        dataLayer.push({
                            'event': 'click_itinerary',
                        })
                    }
                });
            });
        }

    }

    handleCtaSocial() {
        let btnSocials = document.querySelectorAll('.button-social');

        if (btnSocials) {
            Array.from(btnSocials).forEach(btnSocial => {
                btnSocial.addEventListener('click', function (e) {
                    if( typeof dataLayer !== "undefined") {
                        dataLayer.push({
                            'event': 'click_social',
                            'social_media': btnSocial.childNodes[1].getAttribute('alt'),
                        })
                    }
                });
            });
        }

    }

    handleCtas() {
        let ctas = [];

        let firstButtonHeader    = document.querySelector('#inner-first-button-header');
        let secondButtonHeader   = document.querySelector('#inner-second-button-header');
        let doctorButton         = document.querySelector('.medecin-block-link-inner');
        let footerButton         = document.querySelector('.button-footer');
        let rdvButtonCards       = document.querySelectorAll('.card-motif-take-rdv-link-inner');
        let rdvChildButtonCards  = document.querySelectorAll('.show-children-motifs-button');
        let rdvMapButtonCards    = document.querySelectorAll('.underlined-link');
        let wordpressButtons     = document.querySelectorAll('.wp-block-advgb-button_link');
        let doctorDetailButtons  = document.querySelectorAll('.cta.detail');
        let appointmentButtons   = document.querySelectorAll('.cta-appointment');

        if (firstButtonHeader) {
            ctas.push(firstButtonHeader);
        }
        if (secondButtonHeader) {
            ctas.push(secondButtonHeader);
        }
        if (doctorButton) {
            ctas.push(doctorButton);
        }
        if (footerButton) {
            ctas.push(footerButton);
        }
        if (rdvButtonCards) {
            Array.from(rdvButtonCards).forEach(rdvButtonCard => {
                    ctas.push(rdvButtonCard);
            });
        }
        if (rdvChildButtonCards) {
            Array.from(rdvChildButtonCards).forEach(rdvChildButtonCard => {
                ctas.push(rdvChildButtonCard);
            });
        }
        if (rdvMapButtonCards) {
            Array.from(rdvMapButtonCards).forEach(rdvMapButtonCard => {
                if (!rdvMapButtonCard.classList.contains('disable')) {
                    ctas.push(rdvMapButtonCard);
                }
            });
        }
        if (wordpressButtons) {
            Array.from(wordpressButtons).forEach(wordpressButton => {
                ctas.push(wordpressButton);
            });
        }
        if (doctorDetailButtons) {
            Array.from(doctorDetailButtons).forEach(doctorDetailButton => {
                ctas.push(doctorDetailButton);
            });
        }
        if (appointmentButtons) {
            Array.from(appointmentButtons).forEach(appointmentButton => {
                ctas.push(appointmentButton);
            });
        }

        ctas.forEach(cta => {
            cta.addEventListener('click', function (e) {
                if (!cta.classList.contains('disabled')) {
                    let buttonId;
                    if (cta.getAttribute('id') !== null) {
                        buttonId = cta.getAttribute('id');
                    }
                    else {
                        buttonId = cta.classList.item(0);
                    }
                    let buttonLabel = cta.innerText;
                    if( typeof dataLayer !== "undefined") {
                        dataLayer.push({
                            'event': 'click_cta',
                            'button_id': buttonId,
                            'button_label': buttonLabel,
                        })
                    }
                }
            });
        });
    }

    handleFaq() {
        let faqs = document.querySelectorAll('.advgb-accordion-header-title');

        if (faqs) {
            Array.from(faqs).forEach(faq => {
                faq.addEventListener('click', function (e) {
                    if( typeof dataLayer !== "undefined") {
                        dataLayer.push({
                            'event': 'click_faq',
                            'faq': faq.innerText,
                        })
                    }
                });
            });
        }

    }
}