/**
 * @param {*|Array} value
 * @return {Array}
 */
export function arrayWrap(value)
{
    return Array.isArray(value) ? value : [value];
}

/**
 * @param {string} text
 * @return {string}
 */
export function __(text)
{
    return window.ThemeName.translations[text] || text;
}
