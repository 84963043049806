import {arrayWrap} from './helpers';

/**
 * @typedef {Object} Route
 * @property {string|string[]} classes
 * @property {function} controller
 */

export class Router {

    get activeRoute()
    {
        return this._routes.find(route => {
            return arrayWrap(route.classes).some(c => document.body.classList.contains(c));
        });
    }

  /**
   * @param {Route[]} routes
   */
    constructor(routes)
    {
        this._routes = routes;
    }

    start()
    {
        if (this.activeRoute) {
            new this.activeRoute.controller();
        }
    }

}
