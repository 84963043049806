export class PopinController {
    constructor() {
        this.handleAppointmentPopin();
        this.childrenMotifPopin();
        this.conditionTlvPopin();
    }

    handleAppointmentPopin()
    {
        const popin = document.querySelector('.rdv-dispo-popin-container');

        if(popin){
            const close = document.querySelector('.popin .close-popin');
            const btn_appoints = document.querySelectorAll('.rdv-dispo-alaxione .card .card-motif-take-rdv-link a');
            close.onclick = function() {
                popin.classList.toggle('active');
            }

            if(btn_appoints){
                for (const button of btn_appoints) {
                    button.addEventListener('click', function(event) {
                        if(button.classList.contains('disabled')){
                            popin.classList.add('active');
                        }
                    })
                }
            }

            if (window.innerWidth < 1024) {

                const btn_appoints_mobile = document.querySelectorAll('.rdv-dispo-alaxione .card-mobile .card-motif-take-rdv-link-inner');
                if(btn_appoints_mobile){
                    for (const button of btn_appoints_mobile) {
                        button.addEventListener('click', function(event) {
                            if(button.classList.contains('disabled')){
                                popin.classList.add('active');
                            }
                        })
                    }
                }
            }
        }
    }

    childrenMotifPopin()
    {
        // Initialize Variables
        var buttons     = document.querySelectorAll('.show-children-motifs-button');

        Array.from(buttons).forEach(button => {
            button.addEventListener('click', function(e) {
                var popup = button.nextElementSibling;
                if(document.querySelector('.rdv-dispo-alaxione .row .cards .card .children-motifs-popin-container.show')){
                    document.querySelector('.rdv-dispo-alaxione .row .cards .card .children-motifs-popin-container.show').classList.remove('show');
                }
                popup.classList.add('show');

                var close = popup.querySelector('.children-motifs-popin-close');
                close.addEventListener('click', function (e) {
                    popup.classList.remove('show');
                });

                var overlay = popup.nextElementSibling;
                overlay.addEventListener('click', function (e) {
                    popup.classList.remove('show');
                });
            });
        });

        if (window.innerWidth < 1024) {

            const items = document.querySelectorAll('.card-motif-toggle-children-motifs');

            function toggleAccordion() {
                const itemToggle = this.parentNode.nextElementSibling.getAttribute('aria-expanded');
                var i = 0;
                for (i = 0; i < items.length; i++) {
                    items[i].parentNode.nextElementSibling.setAttribute('aria-expanded', 'false');
                }
                if (itemToggle === 'false') {
                    this.parentNode.nextElementSibling.setAttribute('aria-expanded', 'true');
                }
            }
            items.forEach(item => item.addEventListener('click', toggleAccordion));
        }
    }

    conditionTlvPopin() {
        // Initialize Variables
        let btnCondition     = document.querySelector('.poppin-condition');
        let conditionPopin   = document.querySelector('.condition-popin-container');
        let overlay          = document.querySelector('.condition-popin-overlay');
        let close;

        if (conditionPopin) {
            close = conditionPopin.querySelector('.condition-popin-close');
        }

        if (btnCondition) {
            btnCondition.addEventListener('click', function (e) {
                if (conditionPopin.classList.contains('show')) {
                    conditionPopin.classList.remove('show');
                }
                conditionPopin.classList.add('show');
            });
            if (close != null) {
                close.addEventListener('click', function (e) {
                    conditionPopin.classList.remove('show');
                });
            }

            overlay.addEventListener('click', function (e) {
                conditionPopin.classList.remove('show');
            });
        }
    }
}