export class Glossary {
    constructor() {
        var glossary = document.getElementById("glossary");
        if(glossary){
            this.addSmoothScroll();
            this.handleAccordion();
        }
    }

    handleAccordion(){
        var acc = document.getElementsByClassName("accordion-item");
        var i;

        if(acc){
            for (i = 0; i < acc.length; i++) {
                acc[i].addEventListener("click", function() {
                    this.classList.toggle("active");
                    var panel = this.lastChild.previousSibling;
                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                    } else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                    }
                });
            }
        }
    }

    addSmoothScroll(){
        document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth";
    }
}