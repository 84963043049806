export class ScrollController {
    constructor()
    {
        function clickEventOnScrollUp()
        {
            const btnScrollUp = document.querySelector('.scrollUp');
            if (btnScrollUp) {
                btnScrollUp.addEventListener('click',function () {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });
            }
        }
        clickEventOnScrollUp();
    }
}