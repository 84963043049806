export class TestVisionController {
    constructor() {
        //variables pour tester la vision
        this.EchelleTest = 100;
        this.tlvODG = "OD"; //œuil gauche en cours de test.
        this.tslNiveauAcuite = 0.5;
        this.tslOrientationAcuite = 0;
        this.tslLargeurImage = 0;

        //Resultats des tests pour chaque yeux
        this.tlvAcuityOD = 0;
        this.tlvAcuityOG = 0;
        this.tlvAmslerOD = 'non';
        this.tlvAmslerOG = 'non';

        this.StartStepperHandler();
        this.navigateSteps();
        this.nextStepHandler();
        this.calibrationHandle();
        this.distanceVisionHandle();
        this.testAcuity();
        this.dmlaVisionHandle();
        this.testAsmler();
        this.resultTest();
    }

    StartStepperHandler() {
        let startButton = document.querySelector('.tlv-button-start');
        let stepperRow  = document.querySelector('.tlv-block');
        let blockStart  = document.querySelector('.tlv-begin-block');
        if (startButton) {
            startButton.addEventListener('click', function (e) {
                stepperRow.classList.remove('d-none');
                blockStart.classList.add('d-none');
            });
        }
    }

    navigateToFormStep(stepNumber) {
        /**
         * Hide all form steps.
         */
        document.querySelectorAll(".form-step").forEach((formStepElement) => {
            formStepElement.classList.add("d-none");
        });
        /**
         * Mark all form steps as unfinished.
         */
        document.querySelectorAll(".form-stepper-list").forEach((formStepHeader) => {
            formStepHeader.classList.add("form-stepper-unfinished");
            formStepHeader.classList.remove("form-stepper-active", "form-stepper-completed");
        });
        /**
         * Show the current form step (as passed to the function).
         */
        document.querySelector("#step-" + stepNumber).classList.remove("d-none");
        /**
         * Select the form step circle (progress bar).
         */
        const formStepCircle = document.querySelector('li[step="' + stepNumber + '"]');
        /**
         * Mark the current form step as active.
         */
        formStepCircle.classList.remove("form-stepper-unfinished", "form-stepper-completed");
        formStepCircle.classList.add("form-stepper-active");
        /**
         * Loop through each form step circles.
         * This loop will continue up to the current step number.
         * Example: If the current step is 3,
         * then the loop will perform operations for step 1 and 2.
         */
        for (let index = 0; index < stepNumber; index++) {
            /**
             * Select the form step circle (progress bar).
             */
            const formStepCircle = document.querySelector('li[step="' + index + '"]');
            /**
             * Check if the element exist. If yes, then proceed.
             */
            if (formStepCircle) {
                /**
                 * Mark the form step as completed.
                 */
                formStepCircle.classList.remove("form-stepper-unfinished", "form-stepper-active");
                formStepCircle.classList.add("form-stepper-completed");
            }
        }
    }

    navigateSteps() {

        /**
         * Select all form navigation buttons, and loop through them.
         */
        document.querySelectorAll(".btn-navigate-form-step").forEach((formNavigationBtn) => {
            /**
             * Add a click event listener to the button.
             */
            formNavigationBtn.addEventListener("click", () => {
                /**
                 * Get the value of the step.
                 */
                const stepNumber = parseInt(formNavigationBtn.getAttribute("step_number"));

                /**
                 * Call the function to navigate to the target form step.
                 */
                this.navigateToFormStep(stepNumber);
            });
        });

        let appt = this;

        let btnPrevStep3 = document.querySelector('.prev-step-2');
        if (btnPrevStep3) {
            btnPrevStep3.addEventListener('click', function (e) {
                if (btnPrevStep3.classList.contains('btn-navigate-form-step')) {
                    /**
                     * Get the value of the step.
                     */
                    const stepNumber = parseInt(btnPrevStep3.getAttribute("step_number"));

                    /**
                     * Call the function to navigate to the target form step.
                     */
                    appt.navigateToFormStep(stepNumber);

                }
            });
        }

        let btnNextStep3 = document.querySelector('.next-step-3');
        if (btnNextStep3) {
            btnNextStep3.addEventListener('click', function (e) {
                if (btnNextStep3.classList.contains('btn-navigate-form-step')) {
                    /**
                     * Get the value of the step.
                     */
                    const stepNumber = parseInt(btnNextStep3.getAttribute("step_number"));

                    /**
                     * Call the function to navigate to the target form step.
                     */
                    appt.navigateToFormStep(stepNumber);

                }
            });
        }

        let btnNextStep4 = document.querySelector('.next-step-4');
        if (btnNextStep4) {
            btnNextStep4.addEventListener('click', function (e) {
                if (btnNextStep4.classList.contains('btn-navigate-form-step')) {
                    /**
                     * Get the value of the step.
                     */
                    const stepNumber = parseInt(btnNextStep4.getAttribute("step_number"));

                    /**
                     * Call the function to navigate to the target form step.
                     */
                    appt.navigateToFormStep(stepNumber);

                }
            });
        }
    }

    calibrationHandle() {
        let appt = this;

        const data = {
            'echelleTest' : document.getElementById('echelle-test'),
        };

        let buttonMore       = document.querySelector('#zoom-plus');
        let buttonLess       = document.querySelector('#zoom-moins');
        let pieceCalibration = document.querySelector('#piece-one-euro');

        // valeur initiale de la piece
        let initLargeurPiece = 100; // 100%

        // valuer de la piece apres callibration
        let largeurPiece;

        // Mise à l'échelle initiale
        largeurPiece=Math.round(initLargeurPiece/100*2.325/2.54*96);
        if (pieceCalibration) {
            pieceCalibration.style.cssText = "width:"+largeurPiece+"px;";
        }

        if (buttonMore) {
            buttonMore.addEventListener('click', function (e) {
                if (initLargeurPiece<150) {
                    initLargeurPiece+=2;
                    largeurPiece=Math.round(initLargeurPiece/100*2.325/2.54*96);
                    pieceCalibration.style.cssText = "width:"+largeurPiece+"px;";
                    appt.EchelleTest = initLargeurPiece;
                    // calibrage de test
                    data.echelleTest.innerHTML = appt.EchelleTest;
                }
            });
        }
        if (buttonLess) {
            buttonLess.addEventListener('click', function (e) {
                if (initLargeurPiece>80) {
                    initLargeurPiece-=2;
                    largeurPiece=Math.round(initLargeurPiece/100*2.325/2.54*96);
                    pieceCalibration.style.cssText = "width:"+largeurPiece+"px;";
                    appt.EchelleTest = initLargeurPiece;
                    // calibrage de test
                    data.echelleTest.innerHTML = appt.EchelleTest;
                }
            });
        }
    }

    nextStepHandler(){
        let ages                = document.querySelectorAll('input[type=radio][name="age-item-radio"]');
        let corrections         = document.querySelectorAll('input[type=radio][name="correction-item-radio"]');
        let consent             = document.querySelector('input[type=checkbox][name="checkbox-consent"]');
        let nextButtonStep1     = document.querySelector('.next-step-1');
        let acceptanceLeft      = document.querySelector('.btn-accept-left');
        let acceptanceRight     = document.querySelector('.btn-accept-right');
        let acceptanceLeftDmla  = document.querySelector('.btn-accept-left-dmla');
        let acceptanceRightDmla = document.querySelector('.btn-accept-right-dmla');

        let step1Valid        = false;
        let ageChecked        = false;
        let correctionChecked = false;

        ages.forEach((age) => {
            if (age.checked) {
                ageChecked = true;
            }
            age.addEventListener('change',()=> {
                ageChecked = true;
            });
        });

        corrections.forEach((correction) => {
            if (correction.checked) {
                correctionChecked = true;
            }
            correction.addEventListener('change',()=> {
                correctionChecked = true;
            });
        });

        document.addEventListener('change', function (e) {
            if (ageChecked === true && correctionChecked === true) {
                nextButtonStep1.removeAttribute("disabled");
            }
        });

        if (consent) {
            consent.addEventListener('change',()=> {
                document.querySelector('.next-step-2').removeAttribute("disabled");
            });
        }

        if (acceptanceLeft) {
            acceptanceLeft.addEventListener('click', function (e) {
                document.querySelector('.next-step-3').removeAttribute("disabled");
            });
        }

        if (acceptanceRight) {
            acceptanceRight.addEventListener('click', function (e) {
                document.querySelector('.next-step-3').removeAttribute("disabled");
            });
        }

        if (acceptanceLeftDmla) {
            acceptanceLeftDmla.addEventListener('click', function (e) {
                document.querySelector('.next-step-4').removeAttribute("disabled");
            });
        }

        if (acceptanceRightDmla) {
            acceptanceRightDmla.addEventListener('click', function (e) {
                document.querySelector('.next-step-4').removeAttribute("disabled");
            });
        }

    }

    distanceVisionHandle() {
        let intructionLeftEye  = document.querySelector('.instruction-left-eye-row');
        let intructionRightEye = document.querySelector('.instruction-right-eye-row');
        let testEye            = document.querySelector('.test-eye-row');
        let instructionTest    = document.querySelector('.instruction-test');
        let testEyeRow         = document.querySelector('.test-eye');
        let thanksRow          = document.querySelector('.thanks');
        let nextButton         = document.querySelector('.next-step-3');
        let prevbutton         = document.querySelector('.prev-step-2');

        if (nextButton) {
            nextButton.addEventListener('click', function (e) {
                intructionLeftEye.classList.add('d-none');
                testEye.classList.remove('d-none');
                nextButton.setAttribute('disabled', 'true');
                prevbutton.classList.remove('btn-navigate-form-step');
                prevbutton.removeAttribute("step_number");
                prevbutton.classList.add('d-none');
                nextButton.parentElement.classList.add('flex-justify-center');

                if (nextButton.classList.contains('next-eye')) {
                    instructionTest.classList.remove('d-none');
                    testEyeRow.classList.remove('d-none');
                    thanksRow.classList.add('d-none');
                    intructionRightEye.classList.remove('d-none');
                    testEye.classList.add('d-none');
                    nextButton.classList.remove('next-eye');
                }
                else {
                    intructionRightEye.classList.add('d-none');
                }

            });
        }

        if (prevbutton) {
            prevbutton.addEventListener('click', function (e){
                intructionLeftEye.classList.remove('d-none');
                testEye.classList.add('d-none');
                prevbutton.classList.add('btn-navigate-form-step');
                prevbutton.setAttribute('step_number', '2');
            });
        }
    }

    testAcuity() {
        let appt = this;

        //Button directions
        let btnUp    = document.querySelector('#tlvBtnUp');
        let btnRight = document.querySelector('#tlvBtnRight');
        let btnDown  = document.querySelector('#tlvBtnDown');
        let btnLeft  = document.querySelector('#tlvBtnLeft');

        let btnNextStep2 = document.querySelector('.next-step-2');

        //img de test
        let imgTest = document.querySelector('#imgTestEye');

        //progression
        let tslProgression = 1.2589;

        let echelle = 100;

        if (btnNextStep2) {
            btnNextStep2.addEventListener('click', function (e) {
                echelle = appt.EchelleTest;
            });
        }


        // Acuite de départ
        appt.tslNiveauAcuite = 0.5; // AV 0.5 @1m base de départ
        appt.tslOrientationAcuite = Math.floor(Math.random()*4)*90; // Orientation au hasard
        appt.tslLargeurImage = Math.round(echelle/100*1/appt.tslNiveauAcuite*5/60*Math.PI/180*100*96/2,54);

        imgTest.style.cssText = "image-rendering:high-quality;width:"+appt.tslLargeurImage+"px; transform:rotate("+appt.tslOrientationAcuite+"deg);";

        if (btnUp) {
            btnUp.addEventListener('click', function (e) {
                if (appt.tslOrientationAcuite === 0 && appt.tslNiveauAcuite < 1.2) {
                    appt.tslNiveauAcuite *= tslProgression;
                    appt.tslOrientationAcuite=Math.floor(Math.random()*4)*90; // Orientation
                    appt.tslLargeurImage=Math.round(echelle/100*1/appt.tslNiveauAcuite*5/60*Math.PI/180*100*96/2,54);
                    imgTest.style.cssText = "width:"+appt.tslLargeurImage+"px; transform:rotate("+appt.tslOrientationAcuite+"deg);";
                } else {
                    appt.tlvAcuityEnd();
                }
            });
        }

        if (btnRight) {
            btnRight.addEventListener('click', function (e) {
                if (appt.tslOrientationAcuite === 90 && appt.tslNiveauAcuite < 1.2) {
                    appt.tslNiveauAcuite *= tslProgression;
                    appt.tslOrientationAcuite=Math.floor(Math.random()*4)*90; // Orientation
                    appt.tslLargeurImage=Math.round(echelle/100*1/appt.tslNiveauAcuite*5/60*Math.PI/180*100*96/2,54);
                    imgTest.style.cssText = "width:"+appt.tslLargeurImage+"px; transform:rotate("+appt.tslOrientationAcuite+"deg);";
                } else {
                    appt.tlvAcuityEnd();
                }
            });
        }

        if (btnDown) {
            btnDown.addEventListener('click', function (e) {
                if (appt.tslOrientationAcuite === 180 && appt.tslNiveauAcuite < 1.2) {
                    appt.tslNiveauAcuite *= tslProgression;
                    appt.tslOrientationAcuite=Math.floor(Math.random()*4)*90; // Orientation
                    appt.tslLargeurImage=Math.round(echelle/100*1/appt.tslNiveauAcuite*5/60*Math.PI/180*100*96/2,54);
                    imgTest.style.cssText = "width:"+appt.tslLargeurImage+"px; transform:rotate("+appt.tslOrientationAcuite+"deg);";
                } else {
                    appt.tlvAcuityEnd();
                }
            });
        }

        if (btnLeft) {
            btnLeft.addEventListener('click', function (e) {
                if (appt.tslOrientationAcuite === 270 && appt.tslNiveauAcuite < 1.2) {
                    appt.tslNiveauAcuite *= tslProgression;
                    appt.tslOrientationAcuite=Math.floor(Math.random()*4)*90; // Orientation
                    appt.tslLargeurImage=Math.round(echelle/100*1/appt.tslNiveauAcuite*5/60*Math.PI/180*100*96/2,54);
                    imgTest.style.cssText = "width:"+appt.tslLargeurImage+"px; transform:rotate("+appt.tslOrientationAcuite+"deg);";
                } else {
                    appt.tlvAcuityEnd();
                }
            });
        }
    }

    tlvAcuityEnd() {
        const data = {
            'acuityOD'               : document.getElementById('result-acuity-right'),
            'acuityOG'               : document.getElementById('result-acuity-left'),
        };

        let instructionTest = document.querySelector('.instruction-test');
        let testEyeRow      = document.querySelector('.test-eye');
        let thanksRow       = document.querySelector('.thanks');
        let buttonNextStep3 = document.querySelector('.next-step-3');

        instructionTest.classList.add('d-none');
        testEyeRow.classList.add('d-none');
        thanksRow.classList.remove('d-none');

        if (this.tlvODG === "OD") {
            this.tlvAcuityOD = this.tslNiveauAcuite;
            data.acuityOD.innerHTML = this.tlvAcuityOD;
            this.tlvODG = "OG"; //œuil gauche testé passage au droit.
            buttonNextStep3.classList.add('next-eye');
            buttonNextStep3.removeAttribute('disabled');

            this.tslNiveauAcuite = 0.5; // AV 0.5 @1m base de départ
            this.tslOrientationAcuite = Math.floor(Math.random()*4)*90; // Orientation
            this.tslLargeurImage = Math.round(this.EchelleTest/100*1/this.tslNiveauAcuite*5/60*Math.PI/180*100*96/2,54);
            document.getElementById("imgTestEye").style.cssText = "width:"+this.tslLargeurImage+"px; transform:rotate("+this.tslOrientationAcuite+"deg);";
        }
        else {
            this.tlvAcuityOG = this.tslNiveauAcuite;
            data.acuityOG.innerHTML = this.tlvAcuityOG;
            this.tlvODG = "OD";
            buttonNextStep3.classList.add('btn-navigate-form-step');
            buttonNextStep3.setAttribute('step_number', '4');
            buttonNextStep3.removeAttribute('disabled');
        }
    }

    dmlaVisionHandle() {
        let appt = this;

        let intructionLeftEye  = document.querySelector('.dmla-vision .instruction-left-eye-row');
        let intructionRightEye = document.querySelector('.dmla-vision .instruction-right-eye-row');
        let testEye            = document.querySelector('.dmla-vision .test-eye-row');
        let instructionTest    = testEye.querySelector('.instruction-test');
        let testEyeRow         = testEye.querySelector('.test-amsler');
        let thanksRow          = testEye.querySelector('.thanks');
        let nextButton         = document.querySelector('.next-step-4');
        let btnYes             = document.querySelector('.btnYes');
        let btnNo              = document.querySelector('.btnNo');

        nextButton.addEventListener('click', function (e) {
            intructionLeftEye.classList.add('d-none');
            testEye.classList.remove('d-none');
            nextButton.setAttribute('disabled', 'true');

            if (nextButton.classList.contains('next-eye')) {
                instructionTest.classList.remove('d-none');
                testEyeRow.classList.remove('d-none');
                thanksRow.classList.add('d-none');
                intructionRightEye.classList.remove('d-none');
                testEye.classList.add('d-none');
                nextButton.classList.remove('next-eye');
                if (btnYes.classList.contains('validate')) {
                    btnYes.classList.remove('validate');
                }
                if (btnNo.classList.contains('validate')) {
                    btnNo.classList.remove('validate');
                }
                if (btnYes.hasAttribute('disabled')) {
                    btnYes.removeAttribute('disabled');
                }
                if (btnNo.hasAttribute('disabled')) {
                    btnNo.removeAttribute('disabled');
                }
            }
            else if(nextButton.classList.contains('thanks')) {
                instructionTest.classList.add('d-none');
                testEyeRow.classList.add('d-none');
                thanksRow.classList.remove('d-none');
                nextButton.classList.remove('thanks');
                if (nextButton.classList.contains('test-finished')) {
                    nextButton.classList.add('btn-navigate-form-step');
                    nextButton.setAttribute('step_number', '5');
                    nextButton.removeAttribute('disabled');
                    appt.resultAsmler(appt.tlvAmslerOD, appt.tlvAmslerOG, appt.tlvAcuityOD, appt.tlvAcuityOG);
                }
                else {
                    nextButton.classList.add('next-eye');
                    nextButton.removeAttribute('disabled');
                }
            }
            else {
                intructionRightEye.classList.add('d-none');
            }

        });
    }

    testAsmler() {
        let appt = this;

        const data = {
            'asmlerOD' : document.getElementById('result-asmler-right'),
            'asmlerOG' : document.getElementById('result-asmler-left'),
        };

        let testEye            = document.querySelector('.dmla-vision .test-eye-row');
        let instructionTest    = testEye.querySelector('.instruction-test');
        let testEyeRow         = testEye.querySelector('.test-amsler');
        let thanksRow          = testEye.querySelector('.thanks');
        let nextButton         = document.querySelector('.next-step-4');

        let btnYes = document.querySelector('.btnYes');
        let btnNo  = document.querySelector('.btnNo');

        btnYes.addEventListener('click', function (e) {
            btnYes.classList.add('validate');
            btnNo.setAttribute('disabled', 'true');
            if (appt.tlvODG === "OD") {
                appt.tlvODG = "OG";
                appt.tlvAmslerOD = "oui";
                data.asmlerOD.innerHTML = appt.tlvAmslerOD;
                nextButton.classList.add('thanks');
                nextButton.removeAttribute('disabled');
            }
            else {
                appt.tlvAmslerOG = "oui";
                data.asmlerOG.innerHTML = appt.tlvAmslerOG;
                nextButton.classList.add('thanks');
                nextButton.classList.add('test-finished');
                nextButton.removeAttribute('disabled');
            }
        });

        btnNo.addEventListener('click', function (e) {
            btnNo.classList.add('validate');
            btnYes.setAttribute('disabled', 'true');
            if (appt.tlvODG === "OD") {
                appt.tlvODG = "OG";
                appt.tlvAmslerOD = "non";
                data.asmlerOD.innerHTML = appt.tlvAmslerOD;
                nextButton.classList.add('thanks');
                nextButton.removeAttribute('disabled');

            }
            else {
                appt.tlvAmslerOG = "non";
                data.asmlerOG.innerHTML = appt.tlvAmslerOG;
                nextButton.classList.add('thanks');
                nextButton.classList.add('test-finished');
                nextButton.removeAttribute('disabled');
            }
        });

    }

    resultAsmler(amslerOD, amslerOG, acuityOD, acuityOG) {
        const data = {
            'resultAsmlerRight' : document.getElementById('result-test-asmler-right'),
            'resultAsmlerLeft'  : document.getElementById('result-test-asmler-left'),
            'resultGlobalEyes'  : document.getElementById('result-global-eyes'),
            'age'               : document.getElementById('age'),
        };


        //Résultat œil droit
        if (amslerOD === "oui") {
            data.resultAsmlerRight.innerHTML = "Compte tenu du résultat de la grille d'Amsler, nous vous conseillons de prendre rapidement rendez-vous chez votre ophtalmologiste.";
        }
        else {
            if (acuityOD > 0.79) {
                data.resultAsmlerRight.innerHTML = "Le résultat du test de vision de l'Oeil Droit semble normal.";
            }
            else {
                data.resultAsmlerRight.innerHTML = "Selon le résultat du test de vision de l'Oeil droit, vous devriez prendre rendez-vous chez votre ophtalmologiste.";
            }
        }

        //Résultat œil gauche
        if (amslerOG === "oui") {
            data.resultAsmlerLeft.innerHTML = "Compte tenu du résultat de la grille d'Amsler, nous vous conseillons de prendre rapidement rendez-vous chez votre ophtalmologiste.";
        }
        else {
            if (acuityOG > 0.79) {
                data.resultAsmlerLeft.innerHTML = "Le résultat du test de vision de l'Oeil Gauche semble normal.";
            }
            else {
                data.resultAsmlerLeft.innerHTML = "Selon le résultat du test de vision de l'Oeil gauche, vous devriez prendre rendez-vous chez votre ophtalmologiste.";
            }
        }

        // Résultat bi oculaire OD - OG
        if ((acuityOG/acuityOD)<0.7 || (acuityOG/acuityOD)>1.4) {
            data.resultGlobalEyes.innerHTML = "Selon le test, compte tenu de l'écart de vision entre l'oeil droit et l'oeil gauche, nous vous conseillons de prendre rendez-vous chez votre ophtalmologiste.";
        } else {
            data.resultGlobalEyes.innerHTML = "Selon le test réalisé, l'équilibre entre la vision de vos 2 yeux semble correct.";
        }

        //Presbytie
        if (data.age.innerHTML === "45 ans et plus") {
            document.querySelector(".presbytie-row").classList.remove('d-none');
        }

    }

    resultTest() {
        //span des resultats a affiché
        const data = {
            'age'        : document.getElementById('age'),
            'correction' : document.getElementById('correction'),
        };

        let ages = document.querySelectorAll('input[type=radio][name="age-item-radio"]');

        ages.forEach((age) => {
            if (age.checked) {
                data.age.innerHTML = age.value;
            }
            age.addEventListener('change',()=> {
                data.age.innerHTML = age.value;
            });
        });

        let corrections = document.querySelectorAll('input[type=radio][name="correction-item-radio"]');

        corrections.forEach((corrections) => {
            if (corrections.checked) {
                data.correction.innerHTML   = corrections.value;
            }
            corrections.addEventListener('change',()=> {
                data.correction.innerHTML   = corrections.value;
            });
        });

        let btnReload = document.querySelector('.button-reload-test');

        btnReload.addEventListener('click', function (e) {
           window.location.reload();
        });

        this.printResult();

    }

    printResult() {
        let btnPrint        = document.querySelector('.button-print');
        let resultRow       = document.querySelector('.result-row');

        btnPrint.addEventListener('click', function (e) {
            let a = window.open('', '', 'height=500, width=500');

            a.document.write('<html><head>');
            a.document.write("<link rel=\"stylesheet\" id=\"pv-centre-css\" href=\""+window.location.origin+"/app/themes/pv-centre/dist/app.css\" type=\"text/css\" media=\"all\">");
            a.document.write('</head><body class="\print-element\"> <h1>Point Vision : vos résultats de test</h1> <br>');
            a.document.write(resultRow.innerHTML);
            a.document.write('</body></html>');
            a.document.close(); // necessary for IE >= 10
            a.focus(); // necessary for IE >= 10*/
            //a.print();
            setTimeout(function () {
                a.print();
                a.close();
            }, 3000);
            return true;
        });
    }
}