export class NavController {
    constructor()
    {
        this.screenWidth = window.screen.width;

        this.clickEventOnSubMenu();
    }

    clickEventOnSubMenu()
    {
        const menu                        = document.querySelector('.nav');
        const menuItemHasChildrens        = document.querySelectorAll('.menu-item-has-children');
        const menuBackButton              = document.querySelector('.back-menu-mobile');
        const menuLinkButton              = document.querySelector('.link-menu-mobile');
        const headerMenuMobileButton      = document.querySelector('.menu-button-mobile');
        const rowLogoHeader               = document.querySelector('.row-logo-header');
        const rowContactHeader            = document.querySelector('.row-contact-header');
        const breadcrumbs                 = document.querySelector('.breadcrumbs');
        const primary                     = document.querySelector('#primary');
        const root                        = document.querySelector('#root');
        const footer                      = document.querySelector('footer');
        const body                        = document.querySelector('body');
        if (this.screenWidth < 1024) {
            headerMenuMobileButton.addEventListener('click',function () {
                if (this.textContent === "MENU") {
                    this.textContent = "FERMER";
                    menu.classList.add('d-flex');
                    rowContactHeader.classList.add('d-flex');
                    rowLogoHeader.classList.add('d-none');
                    footer.classList.add('d-none');
                    if (primary) {
                        primary.classList.add('d-none');
                    }
                    if (root) {
                        root.classList.add('d-none');
                    }
                    body.classList.add('menu-mobile-open');
                    if (breadcrumbs) {
                        breadcrumbs.classList.add('d-none');
                    }
                } else {
                    this.textContent = "MENU";
                    menu.classList.remove('d-flex');
                    rowContactHeader.classList.remove('d-flex');
                    rowLogoHeader.classList.remove('d-none');
                    footer.classList.remove('d-none');
                    if (primary) {
                        primary.classList.remove('d-none');
                    }
                    if (root) {
                        root.classList.remove('d-none');
                    }
                    body.classList.remove('menu-mobile-open');
                    if (breadcrumbs) {
                        breadcrumbs.classList.remove('d-none');
                    }
                }
            })
            Array.from(document.querySelectorAll('ul li a')).forEach(link => {
                if (link.parentElement.classList.contains('menu-item-has-children')) {
                    link.addEventListener('click', e => {
                        e.preventDefault();
                        menuLinkButton.text = link.innerText;
                        menuLinkButton.href = link.href;
                        link.parentElement.classList.add('clicked');
                        menuLinkButton.classList.add('show-link');
                        menuBackButton.classList.add('show');
                    });
                }
            });
            menuBackButton.addEventListener('click',function () {
                let clickedTable = document.querySelectorAll('.menu .clicked'),
                    lastChild = clickedTable[clickedTable.length - 1];
                lastChild.classList.remove('clicked');

                if (lastChild.parentElement.classList.contains('menu')) {
                    this.classList.remove('show');
                    menuLinkButton.classList.remove('show');
                    menuLinkButton.text = "";
                } else {
                    menuLinkButton.text = lastChild.parentElement.previousElementSibling.innerText;
                }
            });
        } else {
            document.addEventListener('click', function (e) {
                let targetElement = e.target;
                Array.from(menuItemHasChildrens).forEach(menuItemHasChildren => {
                    if (targetElement.parentElement === menuItemHasChildren) {
                        if (menuItemHasChildren.classList.contains('clicked')) {
                            menuItemHasChildren.classList.remove('clicked');
                        } else {
                            if (document.querySelector('header .has-mega-menu.clicked')) {
                                document.querySelector('header .has-mega-menu.clicked').classList.remove('clicked');
                            }
                            menuItemHasChildren.classList.add('clicked');
                        }
                    } else {
                        if (menuItemHasChildren.classList.contains('clicked')) {
                            menuItemHasChildren.classList.remove('clicked');
                        }
                    }
                });
            });
        }
    }
}