export class FirstApptController {
    constructor()
    {
        this.getFirstRdvAlax();
    }

    getFirstRdvAlax()
    {
        const appointment = document.querySelector(".appointment");
        const now   = new Date();
        now.setHours(0,0,0,0);
        const timeout     = Pointvision.variables.timeout;

        if (appointment) {

            const env     = appointment.getAttribute('data-id-alax');
            const idlieu  = appointment.getAttribute('data-id-lieux');
            const motidId = appointment.getAttribute('data-id-motif')

            let urlApi = env+"/ajax/renvoi_premier_dispo_lieu.php?id_lieu=" + idlieu + "&prov=web&id_motif=" + motidId;

            (async () => {
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), timeout);

                try {
                    const response = await fetch(urlApi, {
                        signal: controller.signal
                    }).then((res) => res.json())
                      .then(function (data) {
                          var dateNextRdv = appointment.querySelector('.date');
                          var ctaRdvLink  = appointment.querySelector('.cta-appointment');
                          if (data.length !== 0) {
                            var rdvHref = ctaRdvLink.getAttribute('data-src');

                            Array.from(data).forEach(motif =>{
                                dateNextRdv.innerHTML = "Aucun RDV disponible";
                                ctaRdvLink.classList.add("disabled");
                            });

                            Array.from(data).forEach(motif =>{
                                var idRdv = motif.id_motif;
                                if (idRdv === motidId) {
                                    let datimeMotif = new Date(motif.date);
                                    datimeMotif.setHours(0,0,0,0);
                                    if (now <= datimeMotif) {
                                        dateNextRdv.innerHTML = motif.date_aff;
                                        ctaRdvLink.classList.remove("disabled");
                                        ctaRdvLink.setAttribute('href', rdvHref);
                                        ctaRdvLink.href = ctaRdvLink.href.replace("{{reason}}", idRdv);
                                    } else {
                                        dateNextRdv.innerHTML = "Aucun RDV disponible";
                                        ctaRdvLink.classList.add("disabled");
                                        ctaRdvLink.removeAttribute('href');
                                    }
                                    return false;
                                }
                            });
                          }
                          else {
                            dateNextRdv.innerHTML = "Aucun RDV disponible";
                            ctaRdvLink.classList.add("disabled");
                            ctaRdvLink.removeAttribute('href');
                          }
                      })
                      .catch(function (error) {
                          console.log(error);
                          var dateNextRdv = appointment.querySelector('.date');
                          var ctaRdvLink  = appointment.querySelector('.cta-appointment');

                          dateNextRdv.innerHTML = "Aucun RDV disponible";
                          ctaRdvLink.classList.add("disabled");
                          ctaRdvLink.removeAttribute('href');
                      });
                      console.log(response);
                } catch (error) {
                    console.error(error);
                } finally {
                    clearTimeout(timeoutId);
                }
            })();
        }
    }
}
