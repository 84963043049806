import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";

export class SearchController {
  constructor() {
    this.buildSearch();
    this.buidSelectAge();
    this.selectAgeEvent();
    this.submitSearch();
  }

  selectAgeEvent(){
    const selectReason = document.getElementById("selectReason");
    const selectAge = document.getElementById("selectAge");

    if (selectReason){
      selectReason.addEventListener('change', function() {

        if(this.options[this.selectedIndex].dataset.age === "true"){
          selectAge.disabled = false;
        }else{
          selectAge.disabled = true;
          selectAge.value = "0";
        }
      });
    }
  }

  buidSelectAge(){
    const el = document.getElementById('select-age');
    const selectAge = document.getElementById("selectAge");
    const maxAge = 17;

    if(el){
      el.appendChild(selectAge);

      for (var i = 1; i < maxAge + 1; i++) {
        var option = document.createElement('option');
        selectAge.appendChild(option);
        option.text = [i];
        option.value = [i];
      }
    }
  }

  buildSearch(){
    mapboxgl.accessToken = window.mapboxToken;

    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Entrer une adresse",
      marker:false,
      countries : "FR"
    });

    const searchDiv =  document.getElementById('geo-search')

    if(searchDiv){
      searchDiv.appendChild(geocoder.onAdd());
    }
  }

  submitSearch() {
    const selectReason = document.getElementById("selectReason");
    const txtSearch = document.getElementsByClassName("mapboxgl-ctrl-geocoder--input");
    const btnSubmit = document.getElementById("btnSearch");
    let pattern_search_error = document.querySelector("#pattern-search .error-message");
    let add_search_error = document.querySelector("#geo-search .error-message");

    if(selectReason){
      selectReason.addEventListener("change", function (){
        pattern_search_error.innerText = "";
      });
    }
    
    if(txtSearch.length > 0){
      txtSearch[0].addEventListener("input", function (){
        add_search_error.innerText = "";
      });
    }

    function setErrorMessage(text, current) {
      current.innerText = text;
    }

    function isBlank(str) {
      return (!str || /^\s*$/.test(str));
    }

    if (btnSubmit) {
      btnSubmit.addEventListener("click", function() {
        if (selectReason.value === "0") {
          setErrorMessage("Veuillez choisir un motif.", pattern_search_error)
        }
        if (!txtSearch[0].value) {
          setErrorMessage("Veuillez choisir une adresse.",add_search_error);
        }
        else if (isBlank(txtSearch[0].value)) {
          setErrorMessage("Adresse incorrecte.",add_search_error);
        }
        if (selectReason.value !== "0" && txtSearch[0].value){
          if(!isBlank(txtSearch[0].value)){
            const selectAge = document.getElementById("selectAge");
            const data_result_search = document.querySelector(".rdv-search").getAttribute('data-search-template');
            window.location.href = `${data_result_search}?reason=${selectReason.value}&search=${txtSearch[0].value}&age=${selectAge.value}`;
          }
        }
      });
    }
  }
}
